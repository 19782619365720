import React from 'react';
import { Route, Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Breadcrumb, Form, Layout } from 'antd';

import { LoginPage } from '../user';
import { DashboardPage } from '../dashboard';
import { PropertyPage } from '../property';

import './App.scss';

const { Content } = Layout;

const App = withRouter(connect(mapStateToProps)(props => {
  const WrappedLoginPage = Form.create()(LoginPage);
  console.log(props);

  return (
    <div className="App">
        <Layout className="layout">
            <Content>
            <Route exact path="/" render={() => (
                        !props.auth ?
                          <WrappedLoginPage /> :
                          <DashboardPage />
                    )} />
                    <Route path="/login" render={() => (
                        <WrappedLoginPage />
                    )} />
                    <Route path="/site/:code?" render={() => (
                        <PropertyPage />
                    )} />
                </Content>
                </Layout>
            </div>
  );
}));

function mapStateToProps({ user }) {
  return {
      auth: user.auth,
      user: user.user
  };
}

export default App;
