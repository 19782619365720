import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { Icon, Row, Col } from 'antd';

import { PropertiesComponent } from '../../partials/components/Properties';

class DashboardPage extends Component {
    render() {
        return (
            <div>
                <Row>
                    <Col span={8}>Welcome, User.</Col>
                    <Col span={16}>
                        <PropertiesComponent />
                    </Col>
                </Row>
            </div>
        );
    }
};

export default DashboardPage;