export function fetchUserProperties(user, data) {
    return fetch(`/api/1/properties.json?${data.page ? '/' + data.page : ''}`,
        { headers: { "Authorization": "Bearer " + user.id + ":" + user.token, "Content-Type": "application/json" } })
        .then((res) => {
            if (res.status === 401) {
                console.log('401');
                return Promise.reject(res);
            }
            return res.json();
        })
        .then((data) => data)
        .catch(error => {
            console.log(error);
            return Promise.reject(error);
        });
}