import { userActionTypes as userConstants } from '../';
import { fetchLogin, fetchRegister } from '../api/userApi';

export function doLogin(username, password) {
    return dispatch => {
        dispatch(request({ username }));

        return fetchLogin(username, password)
            .then(
                (data) => {
                    if (!data.error) {
                        localStorage.setItem('user', JSON.stringify(data));
                        dispatch(success(data));
                    } else {
                        dispatch(failure(data));
                    }
                }
            ) /*.
            catch( (data) => alert('1235') );*/
    }

    function request(user) {
        console.log('q');
        console.log(user);
        return {
            type: userConstants.LOGIN_REQUEST,
            user
        }
    }

    function success(user) {
        console.log('s');
        console.log(user);
        return {
            type: userConstants.LOGIN_SUCCESS,
            user
        }
    }

    function failure(user) {
        console.log('f');
        console.log(user);
        return {
            type: userConstants.LOGIN_FAILURE,
            user
        }
    }
}