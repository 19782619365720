import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { Icon } from 'antd';

import './House.scss';

const HouseComponent = (props) => {
    const { property } = props;
    return (
        <div className="property">
            <Link to={props.link}>
                <Icon type="home" />
                <div className="content">
                    <address>
                        <p className="property-address">{ property.address }</p>
                        <p>Apt. { property.apartment }</p>
                    </address>
                    <p>Code: { property.code }</p>
                </div>
            </Link>
        </div>
    );
};

export default HouseComponent;