import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {withRouter} from 'react-router';
import { connect } from 'react-redux';
import { Form, Icon, Input, Button, Checkbox } from 'antd';

import { doLogin } from '../actions/userActions';

import '../styles/login.scss';

const FormItem = Form.Item;

class LoginPage extends Component {
    state = {
        username: '',
        password: ''
    };

    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(e) {
        const name  = e.target.id;
        const value = e.target.type === 'checkbox' ? (e.target.checked ? 1 : 0) : e.target.value;

        this.setState({ [name]: value })
    }

    handleSubmit(e) {
        e.preventDefault();

        const { username, password } = this.state;

        this.props.form.validateFields((err, values) => {
            if (!err) {
              console.log('Received values of form: ', values);
              this.props.doLogin(username, password)
                .then(() => {
                    const { auth, user } = this.props;

                    if (auth === true) {
                        this.props.history.push('/');
                    }
                });
              }
          });
    }
    
    render() {
        const { username, password } = this.state;
        const { getFieldDecorator } = this.props.form;
        const { message } = this.props;
        
        return (
            <div className="login-page">
                <Form onSubmit={this.handleSubmit} className="login-form">
                    { message ? 
                        <span>{message}</span> : ''
                    }
                    <FormItem>
                    {getFieldDecorator('username', {
                        rules: [{ required: true, message: 'Please input your username!' }],
                    })(
                        <Input prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Username" onChange={this.handleChange} />
                    )}
                    </FormItem>
                    <FormItem>
                    {getFieldDecorator('password', {
                        rules: [{ required: true, message: 'Please input your Password!' }],
                    })(
                        <Input prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />} type="password" placeholder="Password" onChange={this.handleChange} />
                    )}
                    </FormItem>
                    <FormItem>
                    {getFieldDecorator('remember', {
                        valuePropName: 'checked',
                        initialValue: true,
                    })(
                        <Checkbox>Remember me</Checkbox>
                    )}
                    <a className="login-form-forgot" href="">Forgot password</a>
                    <Button type="primary" htmlType="submit" className="login-form-button">
                        Log in
                    </Button>
                    </FormItem>
                </Form>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return {
        doLogin: (username, password) => dispatch(doLogin(username, password))
    };
}

function mapStateToProps({ user }) {
    return {
        auth: user.auth,
        message: user.message, 
        user: user.user
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LoginPage));