import { userActionTypes as userConstants } from '../';

const user = JSON.parse(localStorage.getItem('user')) || {};
const initial = { auth: !!user.id, error: '', user: user};

export default function doLogin(state = initial, action) {
    switch (action.type) {
        case userConstants.LOGIN_REQUEST:
            return {
                ...state,
                auth: false,
                message: '',
                user: {}
            }
        case userConstants.LOGIN_SUCCESS:
            return {
                ...state,
                auth: true,
                message: action.user.message,
                user: action.user
            }
        case userConstants.LOGIN_FAILURE:
            return {
                ...state,
                auth: false,
                message: action.user.message,
                user: {}
            }
        case userConstants.USER_LOGOUT:
            return {
                ...state,
                auth: false,
                message: '',
                user: {}
            }
        default:
            return state;
    }
}
