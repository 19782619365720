import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { Icon, Row, Col } from 'antd';

import HouseComponent from '../../partials/components/House';
import PieComponent from '../../partials/components/Pie';
import BarsComponent from '../../partials/components/Bars';

class PropertyPage extends Component {
    render() {
        const property = {
            "code": "02",
            "address": "Av. 123 12-12",
            "apartment": "202"
        };

        return (
            <div>
                <Row>
                    <Col span={8}>
                        <HouseComponent
                            link="#"
                            property={ property } />
                    </Col>
                    <Col span={16}>
                        <BarsComponent />
                    </Col>
                </Row>
                <Row>
                    <Col span={8}>
                        <PieComponent />
                    </Col>
                    <Col span={16}>
                    </Col>
                </Row>
            </div>
        );
    }
};

export default PropertyPage;