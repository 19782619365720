import * as types from '../actions/propertiesActionTypes';

const initial = { properties: [], selected: {} };

export default function getProperties(state = initial, action) {
    switch (action.type) {
        case types.PROPERTIES_SUCCESS:
            return {
                ...state,
                properties: action.properties.properties
            }
        case types.PROPERTY_SUCCESS:
        case types.PROPERTY_SELECT:
            return {
                ...state,
                selected: action.property
            }
        default:
            return state;
    }
}