import { fetchUserProperties } from '../api/propertiesApi';
import * as types from './propertiesActionTypes';

export function getPropertiesByUser(user, data) {
    return dispatch => {
        dispatch(request());

        return fetchUserProperties(user, data)
            .then((data) => {
                if (data) {
                    dispatch(success(data));
                } else {
                    dispatch(failure(data));
                }
            })
            .catch((data) => {
                console.log(data);
                dispatch(failure(data));
            });
    }

    function request() {
        console.log('q');
        return {
            type: types.PROPERTIES_REQUEST
        }
    }

    function success(properties) {
        console.log('s');
        console.log(properties);
        return {
            type: types.PROPERTIES_SUCCESS,
            properties
        }
    }

    function failure() {
        console.log('f');
        return {
            type: types.PROPERTIES_FAILURE
        }
    }
}
