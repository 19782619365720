import React from 'react';
import ReactDOM from 'react-dom';
import { Chart, Geom, Axis, Tooltip, Legend, Coord } from 'bizcharts';

class BarsComponent extends React.Component {
    render() {

// 数据源
const data = [
  { genre: 'Jul', sold: 0, income: 2300 },
  { genre: 'Ago', sold: 0, income: 667 },
  { genre: 'Sep', sold: 0, income: 982 },
  { genre: 'Oct', sold: 0, income: 5271 },
  { genre: 'Nov', sold: 150, income: 3710 }
];

// 定义度量
const cols = {
  sold: { alias: ' ' },
  genre: { alias: ' ' }
};

// 渲染图表
return (
    <Chart width={600} height={250} data={data} scale={cols}>
      <Axis name="genre" title/>
      <Axis name="sold" title/>
      <Legend position="top" dy={-20} />
      <Tooltip />
      <Geom type="interval" position="genre*sold" color="genre" />
    </Chart>
    );
  }
}

export default BarsComponent;
