import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { Button, Table, Divider } from 'antd';

import { propertiesActions } from '../';

import HouseComponent from '../../House';

import '../styles/properties.scss';

class PropertiesComponent extends Component {
    componentDidMount() {
        const { properties, user } = this.props;

        if (!properties.length) {
            this.props.getPropertiesByUser(user, { page: 0 })
                .then(() => {
                    const { auth } = this.props;

                    if (auth === false) {
                        this.props.history.push('/');
                    }
                });
        }
    }

    render() {
        const properties = this.props.properties;
        console.log(properties);

        return (
            <div className="properties">
                {
                    properties.length && properties.map((property, index) => (
                        <HouseComponent
                            key={ index }
                            link={'/site/'+property.code}
                            property={ property } />
                    ))
                }
            </div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    const { getPropertiesByUser } = propertiesActions;

    return {
        getPropertiesByUser: (user, data) => dispatch(getPropertiesByUser(user, data))
    };
}

function mapStateToProps({ properties, user }) {
    console.log(properties.properties);
    return {
        properties: properties.properties,
        user: user.user,
        auth: user.auth,
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PropertiesComponent));
